<template>
  <b-row class="justify-content-md-center match-height">
    <b-col v-for="program in programs" :key="program.id">
      <program-card-array :program="program" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

// Module Situation API Client
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import decimmoStoreModule from "../decimmoStoreModule";

import ProgramCardArray from "./components/ProgramCardArray.vue";

export default {
  components: {
    BRow,
    BCol,

    ProgramCardArray,
  },
  computed: {
    programs() {
      return this.$store.getters["app-comparator/getPrograms"];
    },
  },
  setup() {
    const DECIMMO_APP_STORE_MODULE_NAME = "app-decimmo";

    // Register module
    if (!store.hasModule(DECIMMO_APP_STORE_MODULE_NAME))
      store.registerModule(DECIMMO_APP_STORE_MODULE_NAME, decimmoStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DECIMMO_APP_STORE_MODULE_NAME))
        store.unregisterModule(DECIMMO_APP_STORE_MODULE_NAME);
    });
  },
};
</script>

<style></style>
