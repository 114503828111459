<template>
  <b-card v-if="program && program.name">
    <div class="d-flex align-items-center justify-content-center">
      <b-img
        :src="program.brochure_url"
        :alt="`Image of ${program.name}`"
        class="program-array-img"
        fluid
      />
    </div>

    <div>
      <b-card-text class="text-center">
        <b-row>
          <b-col cols="12">
            <h3 class="text-primary mt-1">
              {{ program.name }}
            </h3>
          </b-col>
          <b-col cols="12">
            <h6>
              {{ program.location.street }} - {{ program.location.zip }} -
              {{ program.location.city }}
            </h6>
          </b-col>
          <b-col cols="12">
            <span>proposé par</span>
            <b-link>
              {{ program.promoter_name }}
            </b-link>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              v-if="!inComparator"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="addProgramToComparator"
              :disabled="isFull"
            >
              Comparer
              <feather-icon icon="CompassIcon" size="15" />
            </b-button>
            <b-button
              v-if="inComparator"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              class="mt-1 mr-1"
              @click="removeProgramFromComparator"
            >
              Retirer
              <feather-icon icon="CompassIcon" size="15" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
              class="mt-1"
              @click="fetchReportData(program.id)"
            >
              Consulter l'analyse
              <feather-icon icon="FileTextIcon" size="15" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>

      <hr class="my-50" />

      <b-table :items="grid_program" :fields="fieldsProgram" small responsive>
      </b-table>

      <hr class="my-50" />

      <b-table
        :items="comparatorData.grid_info"
        :fields="fieldsInfo"
        small
        responsive
      >
      </b-table>

      <hr class="my-1" />

      <b-row>
        <b-col cols="12" md="6">
          <h4>Détails des lots</h4>
          <b-form-checkbox
            v-model="withParking"
            class="custom-control-primary"
            name="check-button"
            switch
            v-if="program.price.full_vat_price_per_meter_parking_included"
          >
            Parking {{ withParking ? "inclus" : "exclus" }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-table :items="grid_lots" :fields="fields" small responsive> </b-table>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BCard,
  BImg,
  BCardText,
  BLink,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";

import { useDecimmoReport } from "./../../searcher/useDecimmoReport";
import { useComparatorData } from "../comparatorData";
import { formatPeriodQuarter } from "@core/utils/filter";

// Custom formater
import {
  currency,
  currencyPriceMeters,
  evalTrend,
  percentage,
  evalMajorPsc,
} from "@core/utils/filter";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTable,
    BCard,
    BImg,
    BCardText,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      withParking: false,
      fieldsProgram: [
        {
          key: "key",
          label: "Programme",
        },
        {
          key: "value",
          label: "",
        },
      ],
      fieldsInfo: [
        {
          key: "key",
          label: "Indicateur",
        },
        {
          key: "value",
          label: "",
        },
      ],
      fields: [
        {
          key: "type",
          label: "Type",
        },
        {
          key: "area_min",
          label: "Surface min",
          formatter: (value) => `${value ? value + "m²" : "-"}`,
        },
        {
          key: "area_max",
          label: "Surface max",
          formatter: (value) => `${value ? value + "m²" : "-"}`,
        },
        {
          key: "price_min",
          label: "Prix min",
          formatter: (value) => `${currency(value)}`,
        },
        {
          key: "price_max",
          label: "Prix max",
          formatter: (value) => `${currency(value)}`,
        },
      ],
    };
  },
  computed: {
    isFull() {
      return this.$store.getters["app-comparator/isFull"];
    },
    inComparator() {
      if (
        this.$store.getters["app-comparator/getPrograms"].find(
          (program) => program.id === this.program.id
        )
      )
        return true;
      return false;
    },
    grid_program() {
      return [
        {
          key: "Prix moyen du programme (parking exclus)",
          value: currencyPriceMeters(
            this.program.price.full_vat_price_per_meter_parking_excepted
          ),
        },
        {
          key: "Prix moyen du programme (parking inclus)",
          value: currencyPriceMeters(
            this.program.price.full_vat_price_per_meter_parking_included
          ),
        },
        {
          key: "Date de commercialisation",
          value: this.program.sale_date,
        },
        {
          key: "Date de livraison",
          value: this.program.delivery_date,
        },
        {
          key: "Total des lots",
          value: this.program.total_lots,
        },
        {
          key: "Financements",
          value: this.program.funding_type,
        },
      ];
    },
    grid_lots() {
      const types = ["t1", "t2", "t3", "t4", "t5"];
      const grid_lots = [];
      for (const type of types) {
        if (this.program[type].volume.initial_stock > 0) {
          const lot = {
            type,
            total: `${this.program[type].volume.available_offer}/${this.program[type].volume.initial_stock}`,
            area_min: this.program[type].living_area.min
              .toString()
              .split(".")[0],
            area_max: this.program[type].living_area.max
              .toString()
              .split(".")[0],
            price_min: this.withParking
              ? this.program[type].full_vat_price.price_parking_included.min
              : this.program[type].full_vat_price.price_parking_excepted.min,
            price_max: this.withParking
              ? this.program[type].full_vat_price.price_parking_included.max
              : this.program[type].full_vat_price.price_parking_excepted.max,
          };
          grid_lots.push(lot);
        } else {
          const lot = {
            type,
          };
          grid_lots.push(lot);
        }
      }

      return grid_lots;
    },
  },
  methods: {
    currency,
    currencyPriceMeters,
    evalTrend,
    percentage,
    evalMajorPsc,
    addProgramToComparator() {
      if (!this.inComparator) {
        this.$store.commit(
          "app-comparator/ADD_PROGRAM_TO_COMPARATOR",
          this.program
        );
        this.$swal({
          title: "Programme ajouté au comparateur",
          icon: "success",
          timer: 1500,
          heightAuto: false,
          showConfirmButton: false,
        });
      }
    },
    removeProgramFromComparator() {
      this.$store.commit(
        "app-comparator/REMOVE_PROGRAM_FROM_COMPARATOR",
        this.program.id
      );
    },
  },
  setup(props) {
    const { comparatorData, fetchComparatorData } = useComparatorData();
    const { fetchReportData } = useDecimmoReport();

    fetchComparatorData(props.program).then(() => {
      comparatorData.value.grid_info = [
        {
          key: "Secteur",
          value: comparatorData.value.demographyByPeriode[0].sector_name,
        },
        {
          key:
            "Prix moyen secteur " +
            formatPeriodQuarter(
              comparatorData.value.prixm2ByPeriode[0].data[5].period
            ),
          value: currencyPriceMeters(
            comparatorData.value.prixm2ByPeriode[0].data[5]
              .price_meter_new_lodgment_without_parking
          ),
        },
        {
          key:
            "Zone Pinel " +
            formatPeriodQuarter(
              comparatorData.value.ventesByPeriode[0].period_last_update
            ),
          value: comparatorData.value.ventesByPeriode[0].pinel.zoning,
        },
        {
          key:
            "Plafond de loyer au m² Pinel " +
            formatPeriodQuarter(
              comparatorData.value.ventesByPeriode[0].period_last_update
            ),
          value: comparatorData.value.ventesByPeriode[0].pinel.rent + "€",
        },
        {
          key: "Rendement locatif Pinel moyen",
          value: percentage(
            (comparatorData.value.ventesByPeriode[0].pinel.rent * 12) /
              comparatorData.value.prixm2ByPeriode[0].data[0]
                .price_meter_new_lodgment_without_parking
          ),
        },
        {
          key:
            "Evolution prix/m² logement neuf entre " +
            formatPeriodQuarter(
              comparatorData.value.prixm2ByPeriode[0].data[0].period
            ) +
            " et " +
            formatPeriodQuarter(
              comparatorData.value.prixm2ByPeriode[0].data[5].period
            ),
          value:
            evalTrend(
              comparatorData.value.prixm2ByPeriode[0].data[5]
                .price_meter_new_lodgment_without_parking,
              comparatorData.value.prixm2ByPeriode[0].data[0]
                .price_meter_new_lodgment_without_parking
            ) + "%",
        },
        {
          key:
            "Evolution loyer/m² entre " +
            comparatorData.value.rentTrend.data[0].year +
            " et " +
            comparatorData.value.rentTrend.data[4].year,
          value:
            evalTrend(
              comparatorData.value.rentTrend.data[4].rent_meter,
              comparatorData.value.rentTrend.data[0].rent_meter
            ) + "%",
        },
        {
          key:
            "Taux d'investisseur " +
            formatPeriodQuarter(
              comparatorData.value.ventesByPeriode[0].data[5].period
            ),
          value: percentage(
            comparatorData.value.ventesByPeriode[0].data[5].investments
              .investor_rate
          ),
        },
        {
          key:
            "Population en " +
            comparatorData.value.demographyByPeriode[0].data[3].year,
          value:
            comparatorData.value.demographyByPeriode[0].data[3].population
              .population +
            " hab / " +
            percentage(
              comparatorData.value.demographyByPeriode[0].data[3].population
                .evolution_population_rate
            ),
        },
        {
          key:
            "Catégorie socioprofessionnelle majoritaire en " +
            comparatorData.value.demographyByPeriode[0].data[3].year,
          value: evalMajorPsc(
            comparatorData.value.demographyByPeriode[0].data[3].employment.psc
          ),
        },
        {
          key:
            "Taux de chômage en " +
            comparatorData.value.demographyByPeriode[0].data[3].year,
          value: percentage(
            comparatorData.value.demographyByPeriode[0].data[3].employment
              .unemployment_rate
          ),
        },
        {
          key:
            "Locataires/Propriétaires/Social en " +
            comparatorData.value.demographyByPeriode[0].data[3].year,
          value:
            "Loc " +
            percentage(
              comparatorData.value.demographyByPeriode[0].data[3].lodgment
                .occupation.tenant_rate
            ) +
            " - " +
            "Prop " +
            percentage(
              comparatorData.value.demographyByPeriode[0].data[3].lodgment
                .occupation.owner_rate
            ) +
            " - " +
            "Social " +
            percentage(
              comparatorData.value.demographyByPeriode[0].data[3].lodgment
                .occupation.social_tenant_rate
            ),
        },
      ];
    });

    return {
      comparatorData,
      fetchReportData,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.program-array-img {
  height: 150px;
}

.itemgrid-features {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 12px;
  li {
    svg,
    i {
      height: 1.4rem;
      width: 1.4rem;
      font-size: 1.4rem;
      margin-right: 0.75rem;
    }
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    span {
      font-weight: $font-weight-bolder;
    }
  }
}
</style>
