import { ref } from "@vue/composition-api";
import store from "@/store";

export const useComparatorData = () => {
  const comparatorData = ref({
    isBusy: false,
    grid_info: [],
    prixm2ByPeriode: [],
    rentTrend: [],
    ventesByPeriode: [],
    demographyByPeriode: [],
  });

  const fetchComparatorData = (program) => {
    return new Promise((resolve, reject) => {
      comparatorData.value.isBusy = true;

      const searchListRentEvolution = {
        nbYears: 5,
        zipCode: Number(program.location.zip),
      };

      const searchYearsGeo = {
        nb_years: 4,
        geo_distances: [
          JSON.stringify({
            distance: 0.01,
            lat: Number(program.location.latitude),
            lng: Number(program.location.longitude),
          }),
        ],
      };

      const searchTempoGeo = {
        frequency: "quarter",
        nb_occurence: 6,
        geo_distances: [
          JSON.stringify({
            distance: 0.01,
            lat: Number(program.location.latitude),
            lng: Number(program.location.longitude),
          }),
        ],
      };

      const promiseListPrixm2ByPeriode = new Promise((resolve, reject) => {
        store
          .dispatch("app-decimmo/listPrixm2ByPeriode", searchTempoGeo)
          .then((response) => {
            comparatorData.value.prixm2ByPeriode = response.data;
            resolve();
          })
          .catch(() => reject());
      });

      const promiseRentEvolution = new Promise((resolve, reject) => {
        store
          .dispatch("app-decimmo/fetchRentEstimation", searchListRentEvolution)
          .then((response) => {
            comparatorData.value.rentTrend = response.data;
            resolve();
          })
          .catch(() => reject());
      });

      const promiseListVentesByPeriode = new Promise((resolve, reject) => {
        store
          .dispatch("app-decimmo/listVentesByPeriode", searchTempoGeo)
          .then((response) => {
            comparatorData.value.ventesByPeriode = response.data;
            resolve();
          })
          .catch(() => reject());
      });

      const promiseDemographyByPeriode = new Promise((resolve, reject) => {
        store
          .dispatch("app-decimmo/listDemographyByPeriode", searchYearsGeo)
          .then((response) => {
            comparatorData.value.demographyByPeriode = response.data;
            resolve();
          })
          .catch(() => reject());
      });

      Promise.all([
        promiseListPrixm2ByPeriode,
        promiseRentEvolution,
        promiseListVentesByPeriode,
        promiseDemographyByPeriode,
      ])
        .then(() => {
          comparatorData.value.isBusy = false;
          resolve();
        })
        .catch(() => reject());
    });
  };

  return {
    // data
    comparatorData,

    // methods
    fetchComparatorData,
  };
};
